import _ from 'lodash';
import React, { useState } from 'react';
import './App.css';
import iccFormHeader1x from './images/icc_form_header_02@1x.png'
import iccFormHeader2x from './images/icc_form_header_02@2x.png'
import iccFormHeader3x from './images/icc_form_header_02@3x.png'

const availableLocales = [
  { code: 'en', text: 'ENGLISH' },
  { code: 'zh', text: '中文' },
];

export default function App() {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [agree, setAgree] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [locale, setLocale] = useState(availableLocales[0])

  async function registerUser() {
    try {
      const newUser = { fullName, phoneNumber, email };
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      });
      const data = await response.text();
      if (response.status !== 200) {
        throw data;
      }
      // Reset form fields
      setFullName('');
      setPhoneNumber('');
      setEmail('');
      setRegistered(true);
      window.scrollTo(0, 0);
      return data;
    } catch (error) {
      console.error(error);
      alert('error: '+ error);
    }
  }

  const handleSubmit = () => {
    // check all fields are filled in
    const _fullName = _.trim(fullName)
    const _phoneNumber = _.trim(phoneNumber)
    const _email = _.trim(email)
    const _agree = !!agree
    if (_.isEmpty(_fullName) || _.isEmpty(_phoneNumber) || _.isEmpty(_email)) {
      alert('Please fill in all fields')
      return
    }
    if (!_agree) {
      alert('Please agree the terms & condititions')
      return
    }
    registerUser()
  }

  const handleLanguageChange = (code) => {
    const selectedLocale = _.find(availableLocales, { code })
    if (selectedLocale) {
      setLocale(selectedLocale)
    }
  }

  if (registered) {
    return (
      <div className="app thank-you-page-background">
        <div className="thank-you-page">
          <a href="https://www.instagram.com/icecreamcookieco.hk/" className="thank-you-page__share-link" target="_blank"></a>
        </div>
      </div>
    )
  }

  return (
    <div className="app register-page">
      <img srcset={`${iccFormHeader1x} 1x, ${iccFormHeader2x} 2x, ${iccFormHeader3x} 3x`} src={iccFormHeader1x} className="hero-banner" />
      <div className="content">
        <p>Fill out the below details and we’ll email you instructions on how to claim your welcome ice cream sandwich</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="row">
            <input
              placeholder="Full Name"
              type="text"
              id="full_name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="row">
            <input
              placeholder="Mobile No. (include '+' sign e.g. +852)"
              type="tel"
              id="phone_number"
              value={phoneNumber}
              onChange={(e) => {
                // Remove non-numeric characters, except for the leading '+'
                const newValue = e.target.value.replace(/[^+\d]/g, '');
                setPhoneNumber(newValue);
              }}
              pattern="^\+\d+$"
              required
            />
            <p className="left"><small>Please ensure you use the same mobile number when registering for our app!</small></p>
          </div>
          <div className="row">
            <input
              placeholder="Email"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="row left">
            <label htmlFor="agree_tnc" className="grid-2-col">
              <input
                type="checkbox"
                id="agree_tnc"
                onChange={e => setAgree(e.target.checked)}
                required
                className="agree-tnc-checkbox"
              />
              <span>I agree to the Terms & Conditions and Data Collection & Use policies</span>
              </label>
          </div>
          <button type="submit">REDEEM</button>
        </form>
      </div>
      <div className="language-selector">
        { _.map(availableLocales, (l) => {
          return (
            <span className="language"><a href="javascript:void(0)" onClick={() => handleLanguageChange(l.code)} className={locale.code === l.code ? 'language-selected' : ''}>{l.text}</a></span>
          )
        })}
      </div>
      <div className="legal-docs">
        { locale.code === 'en' && <React.Fragment>
          <a href="/docs/privacy_policy_en.pdf" target="_blank">Privacy Policy</a> | <a href="/docs/user_agreement_en.pdf" target="_blank">Terms & Conditions</a>
        </React.Fragment> }
        { locale.code === 'zh' && <React.Fragment>
          <a href="/docs/privacy_policy_zh.pdf" target="_blank">私隱條例</a> | <a href="/docs/user_agreement_zh.pdf" target="_blank">使用條款</a>
        </React.Fragment> }
      </div>
    </div>
  );
};
